import React, { Component, Fragment } from "react";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    return (
      <Fragment>
        {(window.location.href = "https://prepaidplus.freshdesk.com/")}
      </Fragment>
    );
  }
}

export default Index;
