//import liraries
import React, { useState, useEffect, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

import format from "date-fns/format";
import moment from "moment";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";

import {
  getCachetTransactionsForDashboard,
  getDepositsByDepositDate,
  clearFinancialTransactions,
} from "../financialActions";

import LoadingComponent from "../../../app/layout/LoadingComponent";
import { openModal } from "../../modals/modalActions";
import classes from "./index.module.css";
import Tabs from "../../../app/UI/Tabs/Tabs";
import Deposits from "./Deposits";
import Reversals from "./Reversals";
import Summary from "./Summary";
import Commissions from "./Commissions";
import Withdrawals from "./Withdrawals";
import Sales from "./Sales";

import { formatMoney } from "../../../app/common/util/helpers";

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId"),
});

const index = ({
  startingBalance,
  closingBalance,
  financials,
  salesTotal,
  sales,
  commissionTotal,
  commissionListTotal,
  commissionCredited,
  earnedCommissionTotal,
  prepaidPlusCommissionTotal,
  commissionList,
  depositsTotal,
  reversalsTotal,
  withdrawalsTotal,
  withdrawalsList,
  openModal,
  initialValues,
  auth,
  profile,
  getCachetTransactionsForDashboard,
  getDepositsByDepositDate,
  clearFinancialTransactions,
}) => {
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());
  let [today, setToday] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  //  async function fetchComment(commentId) {
  //   // You can await here
  // }

  // useEffect(() => {
  //   fetchComment(commentId);
  // }, [commentId]);

  useEffect(() => {
    getCachetTransactionsForDashboard({
      reportStartDate: moment(initialValues.reportStartDate)
        .startOf("day")
        .toDate(),
      reportEndDate: moment(initialValues.reportEndDate).endOf("day").toDate(),
    }).then(() => {
      setLoadingInitial(false);
    });

    return () => {
      clearFinancialTransactions();
    };
  }, []);

  useEffect(() => {
    getDepositsByDepositDate({
      reportStartDate: moment(initialValues.reportStartDate)
        .startOf("day")
        .toDate(),
      reportEndDate: moment(initialValues.reportEndDate).endOf("day").toDate(),
    });

    return () => {
      clearFinancialTransactions();
    };
  }, []);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  // var ts = moment("10/15/2014 9:00", "M/D/YYYY H:mm").valueOf();

  const handleCustomDatesClick = async () => {
    console.log("dates", startDate, endDate);

    let reportStartDate = startDate
      ? startDate.startOf("day").toDate()
      : endDate.startOf("day").toDate();
    let reportEndDate = endDate
      ? endDate.endOf("day").toDate()
      : startDate.endOf("day").toDate();

    console.log("dates", reportStartDate, reportEndDate);

    setLoadingInitial(true);

    console.log("loadingInitial", loadingInitial);

    await getCachetTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    await getDepositsByDepositDate({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    setLoadingInitial(false);
  };

  const handleTodayClick = async () => {
    let reportStartDate = moment().startOf("day").toDate();
    let reportEndDate = moment().endOf("day").toDate();

    setStartDate(moment(reportStartDate));
    setEndDate(moment(reportEndDate));
    setLoadingInitial(true);

    await getCachetTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    await getDepositsByDepositDate({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    setLoadingInitial(false);
  };

  const handleLast7DaysClick = async () => {
    let reportEndDate = moment().endOf("day").toDate();
    let reportStartDate = moment().subtract(7, "days").startOf("day").toDate();

    setStartDate(moment(reportStartDate));
    setEndDate(moment(reportEndDate));
    setLoadingInitial(true);

    await getCachetTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    await getDepositsByDepositDate({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    setLoadingInitial(false);
  };

  const handleMonthToDateClick = async () => {
    let reportStartDate = moment(startDate).startOf("month").toDate();
    let reportEndDate = moment().endOf("day").toDate();

    setStartDate(moment(reportStartDate));
    setEndDate(moment(reportEndDate));
    setLoadingInitial(true);

    await getCachetTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    await getDepositsByDepositDate({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    });

    setLoadingInitial(false);
  };

  const userRole = profile.role;
  const userType = profile.userType;

  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Financials</span>
            <br />
            <span className={classes.ByLine}>
              You are in the Financials panel
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h5>
                        <strong>{format(startDate, "YYYY-MM-DD")}</strong>
                        {` to `}
                        <strong>{format(endDate, "YYYY-MM-DD")}</strong>
                      </h5>
                    </div>
                    <div
                      className={"row"}
                      style={{ alignItems: "center", flex: "1 1 auto" }}
                    >
                      <div className={"col-md-6"}>
                        <div className={classes.FilterWrapper}>
                          <ul className={classes.FilterPeriods}>
                            <li>
                              <span onClick={() => handleTodayClick()}>
                                Today
                              </span>
                            </li>
                            <li>
                              <span onClick={() => handleLast7DaysClick()}>
                                Last 7 Days
                              </span>
                            </li>
                            <li>
                              <span onClick={() => handleMonthToDateClick()}>
                                Month To Date
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className={classes.PageFilter}>
                          <div className={"row"}>
                            <DateRangePicker
                              startDate={startDate} // momentPropTypes.momentObj or null,
                              startDateId="startDate" // PropTypes.string.isRequired,
                              endDate={endDate} // momentPropTypes.momentObj or null,
                              endDateId="endDate" // PropTypes.string.isRequired,
                              onDatesChange={({ startDate, endDate }) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                              }} // PropTypes.func.isRequired,
                              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                              onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                              numberOfMonths={1}
                              isOutsideRange={(date) => date.isAfter(today)}
                              displayFormat={"YYYY-MM-DD"}
                              minimumNights={0}
                              small={true}
                              readOnly={true}
                            />

                            <button
                              className={classes.FilterReportBtn}
                              style={{ marginLeft: "1rem" }}
                              onClick={() => handleCustomDatesClick()}
                            >
                              <svg
                                height="18"
                                width="18"
                                viewBox="0 0 18 18"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path
                                    id="path1"
                                    transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                    fill="#FFFFFF"
                                    d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>

                          {/* <!-- END Form Control--> */}
                        </div>
                      </div>
                    </div>
                  </header>

                  {loadingInitial ? (
                    <LoadingComponent inverted={true} />
                  ) : (
                    <div className={"card card-transparent"}>
                      <div className={classes.CardBody}>
                        <div className={classes.ToolsWrapper}>
                          <div className={classes.Tools}>
                            <div className={"row"}>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Sales for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {salesTotal && formatMoney(salesTotal)}
                                  </h4>
                                </div>
                              </div>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Commission for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {earnedCommissionTotal &&
                                      formatMoney(earnedCommissionTotal)}
                                  </h4>
                                </div>
                              </div>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Deposits for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {depositsTotal &&
                                      formatMoney(depositsTotal)}
                                  </h4>
                                </div>
                              </div>
                              <div className={"col-lg-3"}>
                                <div>
                                  <h5
                                    className={
                                      "all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Reversals for Period
                                  </h5>
                                  <h4 className={"no-margin bold"}>
                                    {reversalsTotal &&
                                      formatMoney(reversalsTotal)}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Tabs>
                          <div label={`Summary`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.Header}>
                                <h4>Summary</h4>
                              </div>
                              <div className={classes.ExportReport}>
                                <div
                                  className={classes.ExportIconWrapper}
                                  onClick={() =>
                                    openModal(
                                      "PrepaidPlusFinancialsSpinnerModal",
                                      {
                                        reportStartDate: moment(
                                          startDate.toDate()
                                        ).format("YYYY-MM-DD"),
                                        reportEndDate:
                                          endDate.format("YYYY-MM-DD"),
                                      }
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                  >
                                    <g>
                                      <path
                                        id="path1"
                                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                        fill="#707070"
                                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </header>
                            <Summary />
                          </div>
                          <div label={`Sales`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Sales</h4>
                              </div>
                            </header>
                            <Sales />
                          </div>
                          <div label={`Commission`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Commission</h4>
                              </div>
                            </header>
                            <Commissions />
                          </div>
                          <div label={`Deposits`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Deposits</h4>
                              </div>
                            </header>
                            <Deposits
                              startDate={initialValues.reportStartDate}
                              endDate={initialValues.reportEndDate}
                            />
                          </div>
                          <div label="Reversals">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Reversals</h4>
                              </div>
                            </header>
                            <Reversals />
                          </div>
                          <div label={`Withdrawals`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Withdrawals</h4>
                              </div>
                            </header>
                            <Withdrawals />
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  startingBalance: state.financials.startingBalance,
  closingBalance: state.financials.closingBalance,
  depositsTotal: state.financials.depositsTotal,
  reversalsTotal: state.financials.reversalsTotal,
  salesTotal: state.financials.salesTotal,
  sales: state.financials.totalsByProvider,
  commissionList: state.financials.commissionList,
  commissionListTotal: state.financials.commissionListTotal,
  commissionTotal: state.financials.commissionTotal,
  withdrawalsList: state.financials.withdrawalsList,
  withdrawalsTotal: state.financials.withdrawalsTotal,
  financials: state.financials.summary,
  commissionCredited: state.financials.creditedCommissionListTotal,
  earnedCommissionTotal: state.financials.earnedCommissionTotal,
  prepaidPlusCommissionTotal: state.financials.vendorCommissionEarnedTotal,
  requesting: state.firestore.status.requesting,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD"),
    reportEndDate: moment().format("YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

const actions = {
  openModal,
  getCachetTransactionsForDashboard,
  getDepositsByDepositDate,
  clearFinancialTransactions,
};

export default compose(connect(mapStateToProps, actions))(
  reduxForm({
    form: "filterItemisedForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  })(index)
);
