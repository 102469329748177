import React, { Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Devices from "./Devices/details";

import LoadingComponent from "../../../../../../app/layout/LoadingComponent";
import classes from "./OutletInventory.module.css";

const outletInventory = ({
  outlet_inventory,
  deviceAllocationContracts,
  requesting,
  outlet,
  group,
  merchantName,
}) => {
  const loading = requesting[`outlet_inventory?where=outletId:==:${outlet.id}`];

  const getDeviceContract = (deviceId) => {
    console.log("getDeviceContract", deviceId);

    let contract = deviceAllocationContracts.find(
      (c) => c.deviceId === deviceId && c.contractStatus !== "Cancelled"
    );

    if (contract) return contract;

    return null;
  };

  let outletInventoryItems =
    outlet_inventory &&
    Object.values(outlet_inventory).map((inventoryItem, index) => {
      return (
        <Devices
          device={inventoryItem}
          deviceContract={
            deviceAllocationContracts &&
            getDeviceContract(inventoryItem.deviceId)
          }
          outlet={outlet}
          group={group}
          merchantName={merchantName}
        />
      );
    });

  return loading ? (
    <Fragment>
      <LoadingComponent inverted={true} />
    </Fragment>
  ) : (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        {outlet_inventory && outlet_inventory.length === 0 ? (
          <div style={{ paddingBottom: "30px" }}>
            <h3>Outlet does not have any devices.</h3>
          </div>
        ) : (
          <Fragment>
            <div className={classes.TableWrapper}>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Model</th>
                    <th className={"hide-s"} scope="col">
                      Network
                    </th>
                    <th className={"hide-s"} scope="col">
                      Cell Number
                    </th>
                    <th className={"hide-s"} scope="col">
                      Is Enabled
                    </th>
                  </tr>
                </thead>
                <tbody>{outletInventoryItems}</tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const query = (props) => {
  console.log("props", props);
  return [
    {
      collection: "outlet_inventory",
      where: ["outletId", "==", props.outlet.id],
    },
    {
      collection: "deviceAllocationContracts",
      where: ["outletId", "==", props.outlet.id],
    },
  ];
};

const mapStateToProps = (state) => ({
  outlet_inventory: state.firestore.ordered.outlet_inventory,
  deviceAllocationContracts: state.firestore.ordered.deviceAllocationContracts,
  profile: state.firebase.profile,
  requesting: state.firestore.status.requesting,
});

export default connect(mapStateToProps)(
  firestoreConnect(query)(outletInventory)
);
