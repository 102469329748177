import React from "react";

import Tabs from "../../../app/UI/Tabs/Tabs";

import Recons from "./recons";
// import UnAllocatedTransactions from "./unallocated";

const index = () => {
  return (
    <Tabs>
      <div label={`FNB Recon`}>
        <Recons />
      </div>
      <div label={`BPC Recon`}>
        {/* <UnAllocatedTransactions /> */}
      </div>
    </Tabs>
  );
};

export default index;
