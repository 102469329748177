import moment from "moment";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-firebase";

import firebase from "../../app/config/firebase";
import { createReversalTransaction } from "../../app/common/util/helpers";
import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTION_SUMMARIES,
} from "./transactionsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
// import { fetchMerchantTransactionsList } from '../../app/data/firebaseAPI';
//import {fetchMerchantTransactionsList} from '../../app/data/firebaseAPI'

// async function search(startsWith = '') {
//   let query = firestore.collection(COLLECTION.CLIENTS);

//   if (startsWith) {
//       const end = startsWith.replace(
//         /.$/, c => String.fromCharCode(c.charCodeAt(0) + 1),
//       );

//       query = query
//         .where('firstName', '>=', startsWith)
//         .where('firstName', '<', end);
//   }

//   const result = await query
//     .orderBy('firstName')
//     .get();

//   return result;
// }

export const searchTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    const outletId = values.outletId;

    // const transactionsStartDate = moment(values.transactionStartDate)
    //   .startOf("day")
    //   .toDate(); // set to 12:00 am today
    // const transactionsEndDate = moment(values.transactionEndDate)
    //   .endOf("day")
    //   .toDate(); // set to 23:59 pm today

    const transactionsStartDate = values.transactionStartDate; // set to 12:00 am today
    const transactionsEndDate = values.transactionEndDate; // set to 23:59 pm today
    const transactionDetail = values.transactionDetail;
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    try {
      dispatch(asyncActionStart());

      // let response = await axios
      //   .post("/api/transactionsSummary", {
      //     reportStartDate: values.reportStartDate,
      //     reportEndDate: values.reportEndDate,
      //   })
      //   .then((response) => {
      //     return response;
      //   });

      // const summary = response.data;

      let query = transactionsRef.where(
        "transactionStatus",
        "==",
        "Successful"
      );

      query = query
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("transactionDetail", "==", transactionDetail)
        .orderBy("transactionDateTime", "desc");

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getOutletTransactionsForDashboard =
  (values, account = "2628") =>
  async (dispatch, getState) => {
    const outletId = values.outletId;

    // const transactionsStartDate = moment(values.transactionStartDate)
    //   .startOf("day")
    //   .toDate(); // set to 12:00 am today
    // const transactionsEndDate = moment(values.transactionEndDate)
    //   .endOf("day")
    //   .toDate(); // set to 23:59 pm today

    const transactionsStartDate = values.transactionStartDate; // set to 12:00 am today
    const transactionsEndDate = values.transactionEndDate; // set to 23:59 pm today
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    try {
      dispatch(asyncActionStart());

      //  let query = transactionsRef.where("transactionDateTime", ">=", transactionsStartDate)
      //  .where("transactionDateTime", "<=", transactionsEndDate)
      //  .where("outletId", "==", outletId)
      //  .where("transactionStatus", "==", "Successful");

      let query = transactionsRef.where(
        "transactionStatus",
        "==",
        "Successful"
      );

      query = query
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("outletId", "==", outletId)
        .orderBy("transactionDateTime", "desc");
      // .limit(50);

      // if (startsWith !== "") {
      //   const end = startsWith.replace(/.$/, (c) =>
      //     String.fromCharCode(c.charCodeAt(0) + 1)
      //   );

      //   query = query
      //     .where("transactionDetail", ">=", startsWith)
      //     .where("transactionDetail", "<", end)
      //     .orderBy("transactionDetail", "desc")
      //     .limit(50);
      // }

      // // const transactions = await query
      // //   // .orderBy('transationDetail')
      // //   .get();

      // // return result;

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getReversalsTransactionsForDashboard =
  (transactionsStartDate, transactionsEndDate) =>
  async (dispatch, getState) => {
    // const transactionsStartDate = transactionStartDate; // set to 12:00 am today
    // const transactionsEndDate = transactionEndDate; // set to 23:59 pm today
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    console.log("transactionStartDate", transactionsStartDate);
    console.log("transactionEndDate", transactionsEndDate);

    try {
      dispatch(asyncActionStart());

      let query = transactionsRef
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("transactionStatus", "==", "Successful")
        .where("transactionType", "==", "Reversal")
        .orderBy("transactionDateTime", "desc");

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      // console.log("transactions", transactions);

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getContractsTransactionsForDashboard =
  (transactionsStartDate, transactionsEndDate) =>
  async (dispatch, getState) => {
    // const transactionsStartDate = transactionStartDate; // set to 12:00 am today
    // const transactionsEndDate = transactionEndDate; // set to 23:59 pm today
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    console.log("transactionStartDate", transactionsStartDate);
    console.log("transactionEndDate", transactionsEndDate);

    try {
      dispatch(asyncActionStart());

      let query = transactionsRef
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("transactionStatus", "==", "Successful")
        .where("transactionType", "==", "DeviceContract")
        .orderBy("transactionDateTime", "desc");

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      // console.log("transactions", transactions);

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getCommissionTransactionsForDashboard =
  (transactionsStartDate, transactionsEndDate) =>
  async (dispatch, getState) => {
    // const transactionsStartDate = transactionStartDate; // set to 12:00 am today
    // const transactionsEndDate = transactionEndDate; // set to 23:59 pm today
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    console.log("transactionStartDate", transactionsStartDate);
    console.log("transactionEndDate", transactionsEndDate);

    try {
      dispatch(asyncActionStart());

      let query = transactionsRef
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("transactionStatus", "==", "Successful")
        .where("transactionType", "==", "Commission")
        .orderBy("transactionDateTime", "desc");

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      // console.log("transactions", transactions);

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getDepositTransactionsForDashboard =
  (transactionsStartDate, transactionsEndDate) =>
  async (dispatch, getState) => {
    // const transactionsStartDate = transactionStartDate; // set to 12:00 am today
    // const transactionsEndDate = transactionEndDate; // set to 23:59 pm today
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    console.log("transactionStartDate", transactionsStartDate);
    console.log("transactionEndDate", transactionsEndDate);

    try {
      dispatch(asyncActionStart());

      let query = transactionsRef
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("transactionStatus", "==", "Successful")
        .where("transactionType", "==", "Deposit")
        .orderBy("transactionDateTime", "desc");

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      // console.log("transactions", transactions);

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    const outletId = values.outletId;

    // const transactionsStartDate = moment(values.transactionStartDate)
    //   .startOf("day")
    //   .toDate(); // set to 12:00 am today
    // const transactionsEndDate = moment(values.transactionEndDate)
    //   .endOf("day")
    //   .toDate(); // set to 23:59 pm today

    const transactionsStartDate = values.transactionStartDate; // set to 12:00 am today
    const transactionsEndDate = values.transactionEndDate; // set to 23:59 pm today

    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    try {
      dispatch(asyncActionStart());

      let query;

      query = transactionsRef
        .where("transactionDateTime", ">=", transactionsStartDate)
        .where("transactionDateTime", "<=", transactionsEndDate)
        .where("transactionStatus", "==", "Successful")
        .orderBy("transactionDateTime", "desc")
        .limit(50);

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getTransactionsSummaryForDashboard =
  (values) => async (dispatch, getState) => {
    const account = values.account;

    const reportStartDate = values.reportStartDate;
    const reportEndDate = values.reportEndDate;

    console.log("reportStartDate", reportStartDate);
    console.log("reportEndDate", reportEndDate);

    try {
      dispatch(asyncActionStart());

      let response = await axios
        .post("/api/transactionsSummary", {
          account: account,
          reportStartDate: values.reportStartDate,
          reportEndDate: values.reportEndDate,
        })
        .then((response) => {
          return response;
        });

      const summary = response.data;

      dispatch({ type: FETCH_TRANSACTION_SUMMARIES, payload: { summary } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const clearTransactionsForDashboard =
  () => async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());

      let transactions = [];
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const reportByCategory = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            saleType: doc.data().saleType,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.saleType in arr))
              arr.__array.push((arr[doc.saleType] = doc));
            else {
              arr[doc.saleType].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    // // console.log("querySnap.docs.length", querySnap.docs.length);
    // let transactions = [];

    // if (querySnap.docs.length === 0) {
    //   dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    //   dispatch(asyncActionFinish());
    //   return querySnap;
    // }

    // for (let i = 0; i < querySnap.docs.length; i++) {
    //   let transaction = {
    //     ...querySnap.docs[i].data(),
    //     id: querySnap.docs[i].id
    //   };
    //   transactions.push(transaction);
    // }

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByDate = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            transactionDateTime: doc
              .data()
              .dateCreated.toDate()
              .toISOString()
              .slice(0, 10),
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.transactionDateTime in arr))
              arr.__array.push((arr[doc.transactionDateTime] = doc));
            else {
              arr[doc.transactionDateTime].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByUser = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            userId: doc.data().createdBy,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.userId in arr)) arr.__array.push((arr[doc.userId] = doc));
            else {
              arr[doc.userId].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// export const reverseTransaction = transaction => async (
//   dispatch,
//   getState
// ) => {};

export const reverseTransaction =
  (transaction) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let dateNow = new Date();
    let transactionCategory =
      transaction.transactionCategory === "Debit" ? "Credit" : "Debit";

    // let isTransactionCharged = transaction.isTransactionCharged ? false : true;

    let reverseTransactionObject = createReversalTransaction(
      transaction,
      transactionCategory,
      user
    );

    try {
      dispatch(asyncActionStart());

      await firestore.add(`transactions`, reverseTransactionObject);

      dispatch(asyncActionFinish());
      toastr.success("Success", "Transaction has been reversed");
    } catch (error) {
      console.log(error);

      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
