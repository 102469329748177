import { SubmissionError, reset } from "redux-form";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import firebase from "../../app/config/firebase";
import axios from "../../axios-firebase";
import { saveAs } from "file-saver";

import {
  FETCH_BALANCES,
  FETCH_DEPOSITS_BY_DEPOSIT_DATE,
} from "./financialsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
import { closeModal } from "../modals/modalActions";

import {
  createBankDepositObject,
  createAdjustmentDepositObject,
  createMerchantWithdrawalObject,
  createTransferToMerchantObject,
  createTransferFromMerchantObject,
  createCreditObject,
} from "../../app/common/util/helpers";

// export const createDeposit = (deposit) => {
export const createDeposit = (deposit) => async (dispatch) => {
  console.log("deposit", deposit);

  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const user = auth.currentUser.displayName;

  let newDeposit = createBankDepositObject(deposit, user);
  try {
    dispatch(asyncActionStart());
    await firestore.collection("transactions").add(newDeposit);

    dispatch(closeModal());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Deposit has been created");
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};

export const createAdjustmentDeposit = (deposit) => {
  console.log("deposit", deposit);

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let newDeposit = createAdjustmentDepositObject(deposit, user);
    try {
      dispatch(asyncActionStart());

      console.log("newDeposit", newDeposit);

      await firestore.collection("transactions").add(newDeposit);

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Deposit has been created");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const createWithdrawal = (withdrawal) => {
  console.log("withdrawal", withdrawal);

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let newWithdrawal = createMerchantWithdrawalObject(withdrawal, user);

    console.log("newWithdrawal", newWithdrawal);

    try {
      dispatch(asyncActionStart());
      await firestore.collection("transactions").add(newWithdrawal);

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Withdrawal has been created");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const amendDeposit = (
  transaction,
  account,
  reportStartDate,
  reportEndDate
) => {
  const deposit = transaction;
  console.log("deposit", deposit);
  console.log("account", account);
  console.log("reportStartDate", reportStartDate);
  console.log("reportEndDate", reportEndDate);

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    try {
      dispatch(asyncActionStart());

      let financialsRef = await firestore
        .collection("merchant_balance")
        .doc(deposit.id);

      let transactionsRef = await firestore
        .collection("transactions")
        .doc(deposit.reference);

      let batch = firestore.batch();

      await batch.update(financialsRef, {
        updatedBy: user,
        dateUpdated: new Date(),
        depositMethod:
          typeof deposit.deposit_method === "string"
            ? deposit.deposit_method
            : deposit.deposit_method.value,
        dateOfDeposit: new Date(deposit.deposit_date),
        bank:
          typeof deposit.deposit_bank === "string"
            ? deposit.deposit_bank
            : deposit.deposit_bank.value,
      });

      await batch.update(transactionsRef, {
        updatedBy: user,
        dateUpdated: new Date(),
        depositMethod:
          typeof deposit.deposit_method === "string"
            ? deposit.deposit_method
            : deposit.deposit_method.value,
        dateOfDeposit: new Date(deposit.deposit_date),
        bank:
          typeof deposit.deposit_bank === "string"
            ? deposit.deposit_bank
            : deposit.deposit_bank.value,
      });

      await batch.commit();

      dispatch(
        getTransactionsForDashboard({
          account: account,
          reportStartDate: reportStartDate,
          reportEndDate: reportEndDate,
        })
      );
      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Deposit has been amended");
    } catch (error) {
      console.log("amend deposit error", error);
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const createCommissionDeposit = (deposit) => {
  const now = new Date();

  let doc = deposit.values;

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let commissionDepositRecord = {
      account: doc.account,
      merchantName: doc.merchantName,
      commissionType: doc.commissionType,
      commissionRate: doc.commission_rate + "%",
      vendorCommissionRate: doc.vendor_commission_rate + "%",
      numberOfTransactions: 0,
      provider: doc.provider,
      vendorCommissionAmount: doc.vendorCommissionAmount,
      transactionAmount: doc.commissionAmount,
      sales: parseFloat(doc.sales_amount),
      createdBy: user,
      dateCreated: now,
      isTransactionCharged: false,
      transactionCategory: doc.transactionCategory,
      transactionDateTime: now,
      transactionStatus: "Successful",
      transactionType: doc.transactionType,
    };

    console.log("manual deposit", commissionDepositRecord);

    try {
      dispatch(asyncActionStart());
      await firestore.collection("transactions").add(commissionDepositRecord);

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Commission Deposit has been created");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const createIntraMerchantTransfer = (transfer) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let transferToMerchant = createTransferToMerchantObject(transfer, user);
    let transferFromMerchant = createTransferFromMerchantObject(transfer, user);

    try {
      dispatch(asyncActionStart());

      let transferToMerchantRef = await firestore
        .collection("transactions")
        .doc();

      let transferFromMerchantRef = await firestore
        .collection("transactions")
        .doc();

      let batch = firestore.batch();

      await batch.set(transferToMerchantRef, transferToMerchant);
      await batch.set(transferFromMerchantRef, transferFromMerchant);

      await batch.commit();

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Merchant transfer has been done");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const getDepositsByDepositDate =
  (values) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const reportStartDate = values.reportStartDate;
    const reportEndDate = values.reportEndDate;

    try {
      dispatch(asyncActionStart());

      const depositsByDepositDate = await firestore
        .collection("merchant_balance")
        .where("dateOfDeposit", ">=", reportStartDate)
        .where("dateOfDeposit", "<=", reportEndDate)
        .where("transactionType", "==", "Deposit")
        .orderBy("dateOfDeposit")
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.size > 0) {
            let balances = [];

            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let transaction = {
                ...querySnapshot.docs[i].data(),
                id: querySnapshot.docs[i].id,
              };

              balances.push(transaction);
            }

            return {
              depositsByDepositDateTotal: balances
                .filter((item) => item.transactionType === "Deposit")
                .reduce(function (total, doc) {
                  total += parseFloat(doc.transactionAmount)
                    ? parseFloat(doc.transactionAmount)
                    : 0;

                  return total;
                }, 0),
              depositsByDepositDateList: balances
                .filter((item) => item.transactionType === "Deposit")
                .map((doc) => {
                  return {
                    bank: doc.bank,
                    account: doc.account,
                    merchantName: doc.merchantName,
                    dateOfDeposit: doc.dateOfDeposit
                      ? doc.dateOfDeposit.toDate()
                      : doc.transactionDateTime.toDate(),
                    depositMethod: doc.depositMethod,
                    transactionAmount: doc.transactionAmount,
                    transactionDateTime: doc.transactionDateTime.toDate(),
                  };
                }),
            };
          } else {
            let response = {
              depositsTotal: 0,
              depositsList: [],
            };
            return response;
          }
        });

      // return financials;

      // const financials = response.data;
      // console.log("financials", financials);

      dispatch({
        type: FETCH_DEPOSITS_BY_DEPOSIT_DATE,
        payload: { depositsByDepositDate },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const createCredit = (credit, expiryDate) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let newCredit = createCreditObject(credit, expiryDate, user);
    try {
      dispatch(asyncActionStart());
      await firestore.collection("credit").add(newCredit);

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Credit has been created");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const approveCredit = (credit) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    console.log("credit", credit);

    try {
      dispatch(asyncActionStart());
      let approvedCreditFacilityRef = await firestore
        .collection("credit")
        .where("account", "==", credit.account)
        .where("status", "==", "Approved");

      let approvedCreditList = await approvedCreditFacilityRef
        .get()
        .then((querySnapshot) => {
          let approvedCredit = [];

          if (querySnapshot.size > 0) {
            approvedCredit = querySnapshot.docs.map((doc) => {
              let facility = doc.data();
              facility.id = doc.id;
              return facility;
            });
          }

          return approvedCredit;
        });

      let approvedCreditRef = await firestore
        .collection("credit")
        .doc(credit.id);

      let batch = firestore.batch();

      approvedCreditList.forEach(async (creditFacility) => {
        let approvedCreditRef = firestore
          .collection("credit")
          .doc(creditFacility.id);

        await batch.update(approvedCreditRef, { status: "Closed" });
      });

      await batch.update(approvedCreditRef, {
        actionedBy: currentUser,
        status: "Approved",
        dateActioned: now,
      });

      await batch.commit();
      dispatch(asyncActionFinish());
      dispatch(closeModal());
      toastr.success("Success", "Credit Facility has been approved");
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const declineCredit = (credit) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    try {
      dispatch(asyncActionStart());
      await firestore.collection("credit").doc(credit.id).set(
        {
          actionedBy: currentUser,
          status: "Declined",
          dateActioned: now,
        },
        { merge: true }
      );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Credit Facility has been declined");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
      dispatch(asyncActionError());
    }
  };
};

export const getTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    const account = values.account;

    const reportStartDate = values.reportStartDate;
    const reportEndDate = values.reportEndDate;

    console.log("reportStartDate", reportStartDate);
    console.log("reportEndDate", reportEndDate);

    try {
      dispatch(asyncActionStart());

      // const depositsByDepositDate = await firestore
      //   .collection("merchant_balance")
      //   .where("dateOfDeposit", ">=", reportStartDate)
      //   .where("dateOfDeposit", "<=", reportEndDate)
      //   .where("transactionType", "==", "Deposit")
      //   .orderBy("dateOfDeposit")
      //   .get()
      //   .then(async (querySnapshot) => {
      //     if (querySnapshot.size > 0) {
      //       let balances = [];

      //       for (let i = 0; i < querySnapshot.docs.length; i++) {
      //         let transaction = {
      //           ...querySnapshot.docs[i].data(),
      //           id: querySnapshot.docs[i].id,
      //         };

      //         balances.push(transaction);
      //       }

      //       return {
      //         depositsByDepositDateTotal: balances
      //           .filter((item) => item.transactionType === "Deposit")
      //           .reduce(function (total, doc) {
      //             total += parseFloat(doc.transactionAmount)
      //               ? parseFloat(doc.transactionAmount)
      //               : 0;

      //             return total;
      //           }, 0),
      //         depositsByDepositDateList: balances
      //           .filter((item) => item.transactionType === "Deposit")
      //           .map((doc) => {
      //             return {
      //               bank: doc.bank,
      //               account: doc.account,
      //               merchantName: doc.merchantName,
      //               dateOfDeposit: doc.dateOfDeposit
      //                 ? doc.dateOfDeposit.toDate()
      //                 : doc.transactionDateTime.toDate(),
      //               depositMethod: doc.depositMethod,
      //               transactionAmount: doc.transactionAmount,
      //               transactionDateTime: doc.transactionDateTime.toDate(),
      //             };
      //           }),
      //       };
      //     } else {
      //       let response = {
      //         depositsTotal: 0,
      //         depositsList: [],
      //       };
      //       return response;
      //     }
      //   });

      let response = await axios
        .post("/api/financials", {
          account: account,
          reportStartDate: values.reportStartDate,
          reportEndDate: values.reportEndDate,
        })
        .then((response) => {
          return response;
        });

      const financials = response.data;

      dispatch({ type: FETCH_BALANCES, payload: { financials } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const getCachetTransactionsForDashboard =
  (values) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const reportStartDate = values.reportStartDate;
    const reportEndDate = values.reportEndDate;

    console.log("reportStartDate", reportStartDate);
    console.log("reportEndDate", reportEndDate);

    // const firestore = firebase.firestore();

    // const transactionsRef = firestore.collection("merchant_balance");

    try {
      dispatch(asyncActionStart());

      const depositsByDepositDate = await firestore
        .collection("merchant_balance")
        .where("dateOfDeposit", ">=", reportStartDate)
        .where("dateOfDeposit", "<=", reportEndDate)
        .where("transactionType", "==", "Deposit")
        .orderBy("dateOfDeposit")
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.size > 0) {
            let balances = [];

            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let transaction = {
                ...querySnapshot.docs[i].data(),
                id: querySnapshot.docs[i].id,
              };

              balances.push(transaction);
            }

            return {
              depositsByDepositDateTotal: balances
                .filter((item) => item.transactionType === "Deposit")
                .reduce(function (total, doc) {
                  total += parseFloat(doc.transactionAmount)
                    ? parseFloat(doc.transactionAmount)
                    : 0;

                  return total;
                }, 0),
              depositsByDepositDateList: balances
                .filter((item) => item.transactionType === "Deposit")
                .map((doc) => {
                  return {
                    bank: doc.bank,
                    createdBy: doc.createdBy,
                    account: doc.account,
                    merchantName: doc.merchantName,
                    dateOfDeposit: doc.dateOfDeposit
                      ? doc.dateOfDeposit.toDate()
                      : doc.transactionDateTime.toDate(),
                    depositMethod: doc.depositMethod,
                    transactionAmount: doc.transactionAmount,
                    transactionDateTime: doc.transactionDateTime.toDate(),
                  };
                }),
            };
          } else {
            let response = {
              depositsByDepositDateTotal: 0,
              depositsByDepositDateList: [],
            };
            return response;
          }
        });

      // return await admin.db
      // .collection("vendorBalances")
      // .get()
      // .then((querySnapshot) => {
      //   querySnapshot.forEach(async (doc) => {
      //     return {
      //       balance: doc.data().balance,
      //       vendor: doc.data().vendor,
      //     };
      //   });
      // });

      const vendorBalances = await firestore
        .collection("vendorBalances")
        .get()
        .then(async (querySnapshot) => {
          console.log("vendorBalances querySnapshot", querySnapshot.size);

          if (querySnapshot.size > 0) {
            let balances = [];

            for (const doc of querySnapshot.docs) {
              let transaction = {
                balance: doc.data().balance,
                vendor: doc.data().vendor,
              };

              balances.push(transaction);
            }

            return balances;
          }
        });

      console.log("vendorBalances", vendorBalances);

      let response = await axios
        .post("api/prepaidplusFinancials", {
          reportStartDate: values.reportStartDate,
          reportEndDate: values.reportEndDate,
        })
        .then((response) => {
          return response;
        });

      const financials = response.data;

      // financials.vendorBalances = vendorBalances;
      financials.depositsByDepositDateTotal =
        depositsByDepositDate.depositsByDepositDateTotal;
      financials.depositsByDepositDateList =
        depositsByDepositDate.depositsByDepositDateList;

      console.log("financials", financials);

      dispatch({ type: FETCH_BALANCES, payload: { financials } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const getCachetTransactionsForExport =
  (startDate, endDate) => async (dispatch) => {
    console.log("start,end", startDate, endDate);

    try {
      dispatch(asyncActionStart());

      const reportName = "Merchant Financials";
      const now = Date.now();

      await axios
        .post("api/prepaidplusFinancialsExcel", {
          reportStartDate: startDate,
          reportEndDate: endDate,
        })
        .then((response) => {
          let buff = new Buffer(response.data.base64, "base64");

          const blob = new Blob([buff], { type: "application/octet-stream" });
          saveAs(blob, `${reportName}-${now}.xlsx`);
        });

      dispatch(closeModal());

      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(closeModal());
      dispatch(asyncActionError());
    }
  };

export const getTransactionsForExport =
  (account, merchantName, startDate, endDate) => async (dispatch) => {
    console.log("start,end", startDate, endDate);

    try {
      dispatch(asyncActionStart());

      const reportName = "Merchant Financials";
      const now = Date.now();

      await axios
        .post("api/financialsExcel", {
          account: account,
          merchantName: merchantName,
          reportStartDate: startDate,
          reportEndDate: endDate,
        })
        .then((response) => {
          let buff = new Buffer(response.data.base64, "base64");

          const blob = new Blob([buff], { type: "application/octet-stream" });
          saveAs(blob, `${reportName}-${now}.xlsx`);
        });

      dispatch(closeModal());

      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(closeModal());
      dispatch(asyncActionError());
    }
  };

export const clearFinancialTransactions = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let financials = [];

    dispatch({ type: FETCH_BALANCES, payload: { financials } });

    dispatch(asyncActionFinish());
  } catch (error) {
    if (error) {
      console.log(error);
    }

    dispatch(asyncActionError());
  }
};

export const suspendFacility = (creditFacilityId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    console.log("credit limit in suspendFacility", creditFacilityId);

    try {
      dispatch(asyncActionStart());
      await firestore.collection("credit").doc(creditFacilityId).set(
        {
          actionedBy: currentUser,
          status: "Suspended",
          dateActioned: now,
        },
        { merge: true }
      );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Facility has been suspended");
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      dispatch(asyncActionError());
    }
  };
};
