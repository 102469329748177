import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import format from "date-fns/format";
import { moneyFormat } from "../../../../../../../../app/common/util/moneyFormat";
import { openModal } from "../../../../../../../modals/modalActions";
import classes from "./details.module.css";
import Tabs from "../../../../../../../../app/UI/Tabs/Tabs";
import LoadingComponent from "../../../../../../../../app/layout/LoadingComponent";

import EnableDevice from "../../Devices/EnableDevice";
import DisableDevice from "../../Devices/DisableDevice";
import GeneratePairingCode from "../../Devices/GeneratePairingCode";
import UnassignDevice from "../../Devices/UnassignDevice";

const detail = (props) => {
  let {
    openModal,
    device,
    deviceContract,
    deviceTransactions,
    outlet,
    group,
    merchantName,
  } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    // return sortedDeviceTransactions;
  }, [expanded]);

  if (!deviceTransactions) return <LoadingComponent inverted={true} />;

  let paymentTotal =
    props.deviceTransactions.length > 0
      ? deviceTransactions.reduce(function (accumulator, transaction) {
          return accumulator + transaction.transactionAmount;
        }, 0)
      : 0;

  let sortedDeviceTransactions = [...deviceTransactions];

  // sortedDeviceTransactions.sort((a, b) => {
  //   if (a.transactionDateTime.toDate() < b.transactionDateTime.toDate()) {
  //     return -1;
  //   }
  //   if (a.transactionDateTime.toDate() > b.transactionDateTime.toDate()) {
  //     return 1;
  //   }

  //   return 0;
  // });

  // sortedDeviceTransactions.sort((a, b) => a - b);

  console.log("sortedDeviceTransactions", sortedDeviceTransactions);

  let sortedTransactions = sortedDeviceTransactions.sort(function (a, b) {
    return a.recordId - b.recordId;
  });

  console.log("sortedTransactions", sortedTransactions);

  // import store from "./myStore";

  // const state = store.getState();
  // const getItems = state => state.user.items;
  // // ↓ in my case items is an array, so I create a new array by spreading state here
  // const items = [...getItems(state)];
  // // ↓ which means we're not manipulating state, but just our `items` array alone
  // items.sort((a, b) => a.order - b.order);

  console.log("props.deviceTransactions", props.deviceTransactions);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={"card card-default"}>
          <div className={"card-body"}>
            <div className={"row"}>
              <div className={"card card-transparent"}>
                <div className={"card-body"} style={{ padding: 0 }}>
                  <Tabs>
                    <div label="Manage Device">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          <div className={classes.EnableDevice}>
                            <div className={classes.EnableDevice}>
                              <div className={"card card-transparent"}>
                                <div
                                  className={"card-body"}
                                  style={{ paddingLeft: 0 }}
                                >
                                  <div className={"panel"}>
                                    <Fragment>
                                      <ul
                                        className={
                                          "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                        }
                                      >
                                        <li className={"nav-item active"}>
                                          <a
                                            data-toggle="tab"
                                            href="#activation"
                                            className={"active show"}
                                          >
                                            {device.isActivated
                                              ? "Disable"
                                              : "Enable"}
                                          </a>
                                        </li>
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#pairing">
                                            Pairing
                                          </a>
                                        </li>
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#unlink">
                                            Unlink
                                          </a>
                                        </li>
                                        {/* <li className={"nav-item active"}>
                                          <a
                                            data-toggle="tab"
                                            href="#agreement"
                                          >
                                            Agreement
                                          </a>
                                        </li> */}
                                      </ul>
                                      <div className={"tab-content"}>
                                        <div
                                          className={"tab-pane active"}
                                          id="activation"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              {device.isActivated ? (
                                                <DisableDevice
                                                  device={device}
                                                  outletId={device.outletId}
                                                  account={device.account}
                                                />
                                              ) : (
                                                <EnableDevice
                                                  device={device}
                                                  outletId={device.outletId}
                                                  account={device.account}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={"tab-pane"}
                                          id="pairing"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <GeneratePairingCode
                                                  device={device}
                                                  outlet={outlet}
                                                  merchantName={merchantName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={"tab-pane"} id="unlink">
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <UnassignDevice
                                                  device={device}
                                                  outlet={outlet}
                                                  merchantName={merchantName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div
                                          className={"tab-pane"}
                                          id="agreement"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <GeneratePairingCode
                                                  device={device}
                                                  outlet={outlet}
                                                  merchantName={merchantName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                    </Fragment>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div label="Contract">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          {deviceContract ? (
                            <>
                              <div className={"row p-t-10"}>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Plan
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {deviceContract.plan}
                                  </h5>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Contract Status
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {deviceContract.contractStatus}
                                    </h5>
                                  </div>
                                </div>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Contract Amount
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {deviceContract.contractAmount}
                                  </h5>
                                </div>
                              </div>

                              <div className={"row p-t-10"}>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Installment &amp; Duration
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {`P${moneyFormat(
                                      deviceContract.installmentAmount
                                    )} over
                                      ${
                                        deviceContract.installmentPeriodInMonths
                                      } Months`}
                                  </h5>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Amount Paid(including Deposit)
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {`P${moneyFormat(
                                        deviceContract.amountPaid
                                      )}`}
                                    </h5>
                                  </div>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Amount Outstanding
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {/* {`P${moneyFormat(
                                        deviceContract.amountOutstanding
                                      )}`} */}

                                      {`P${moneyFormat(
                                        deviceContract.amountOutstanding
                                      )} over
                                      ${
                                        deviceContract.contractRemaining
                                      } Months`}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div className={"row p-t-10"}>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Date Allocated
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {format(
                                      deviceContract.dateCreated.toDate(),
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </h5>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Installment Start
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {format(
                                        deviceContract.contractStartDate.toDate(),
                                        "YYYY-MM-DD"
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Installment End
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {format(
                                        deviceContract.contractEndDate.toDate(),
                                        "YYYY-MM-DD"
                                      )}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div className={"wizard-footer p-t-30"}>
                                <button
                                  className={`${classes.AmendContactBtn} m-r-30`}
                                  onClick={() =>
                                    openModal("AmendDeviceContractModal", {
                                      deviceContract: deviceContract,
                                      outletName: outlet.name,
                                      group: group,
                                      merchantName: merchantName,
                                    })
                                  }
                                >
                                  Amend Contract {"  "}
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                                <button
                                  className={`${classes.AmendContactBtn} m-r-30`}
                                  onClick={() =>
                                    openModal("AdhocDevicePaymentModal", {
                                      deviceContract: deviceContract,
                                      outletName: outlet.name,
                                      group: group,
                                      merchantName: merchantName,
                                    })
                                  }
                                >
                                  Adhoc Payment
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                                <button
                                  className={classes.AmendContactBtn}
                                  onClick={() =>
                                    openModal("ManualDevicePaymentModal", {
                                      deviceContract: deviceContract,
                                      outletName: outlet.name,
                                      group: group,
                                      merchantName: merchantName,
                                    })
                                  }
                                >
                                  Installment Payment
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                                <div className={"pull-right"} />
                                <div className={"clearfix"} />
                              </div>
                            </>
                          ) : (
                            <h4>Device has no contract</h4>
                          )}
                        </div>
                      </div>
                    </div>
                    <div label="Transactions">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          {sortedTransactions.length > 0 ? (
                            <div className={"row"}>
                              <div className={"card card-transparent"}>
                                <div className={"card-body"}>
                                  {
                                    <table className={classes.MerchantTable}>
                                      <thead>
                                        <tr>
                                          <th scope="col">Date Time</th>
                                          <th scope="col">Type</th>
                                          <th style={{ textAlign: "right" }}>
                                            Amount
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            Total Paid
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            Balance Remaining
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            contractBalance
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            Contract Remaining
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {sortedTransactions.map(
                                          (transaction, index) => {
                                            return (
                                              <tr
                                                className={classes.MerchantRow}
                                                key={index}
                                              >
                                                <td>
                                                  {format(
                                                    transaction.transactionDateTime.toDate(),
                                                    "YYYY-MM-DD  HH:mm:ss"
                                                  )}
                                                </td>
                                                <td>
                                                  {`${transaction.deviceContractTransactionType}`}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.transactionAmount
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.amountPaid
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.contractBalance
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.contractBalance
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {
                                                    transaction.contractRemaining
                                                  }
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr className={classes.Totals}>
                                          <td colSpan="2" align="right">
                                            <div
                                              className={
                                                classes.MoneyTotalLabel
                                              }
                                            >
                                              {" "}
                                              Total:
                                            </div>
                                          </td>
                                          <td
                                            className={classes.MoneyTotal}
                                            align="right"
                                          >
                                            {moneyFormat(paymentTotal)}
                                          </td>
                                          <td colSpan="4"> </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  }
                                </div>
                              </div>
                            </div>
                          ) : (
                            <h4>Device has no transactions</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const query = (props) => {
  if (!props.deviceContract) {
    return [
      {
        collection: "merchant_balance",
        storeAs: "deviceTransactions",
        where: [
          ["transactionType", "==", "DeviceContract"],
          ["account", "==", "1913"],
        ],
      },
    ];
  }

  return [
    {
      collection: "merchant_balance",
      storeAs: "deviceTransactions",
      where: [
        ["transactionType", "==", "DeviceContract"],
        ["account", "==", props.deviceContract.account],
      ],
    },
  ];
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  deviceTransactions: state.firestore.ordered.deviceTransactions,
});

const actions = { openModal };

export default compose(
  connect(mapStateToProps, actions),
  firestoreConnect(query)
)(detail);

// export default compose(
//   connect(mapStateToProps, actions),
//   reduxForm({
//     form: "registerDeviceWithOutlet",
//     destroyOnUnmount: true,
//     forceUnregisterOnUnmount: true,
//     validate,
//   }),
//   firestoreConnect(query)
// )(RegisterDeviceWithOutlet);

// const mapStateToProps = (state) => ({
//   devices:
//     state.firestore.ordered.unallocatedInventory &&
//     state.firestore.ordered.unallocatedInventory
//       .filter((item) => item.deviceType === "Device")
//       .map((item) => {
//         return {
//           label: `${item.manufacturer} ${item.model} - ${item.macAddress} - ${item.cellNumber}`,
//           value: item,
//         };
//       }),
//   merchantBalance:
//     state.firestore.ordered.merchantBalance &&
//     state.firestore.ordered.merchantBalance[0],
//   devicePaymentPlans:
//     state.firestore.ordered.devicePaymentPlans &&
//     state.firestore.ordered.devicePaymentPlans.map((item) => {
//       return {
//         label: `${item.plan}`,
//         value: item,
//       };
//     }),

//   initialValues: {
//     outletId: state.modals.modalProps.outletId,
//     account: state.modals.modalProps.account,
//     outletName: state.firestore.ordered.outlet[0].name,
//     merchantName: state.firestore.ordered.merchant[0].name,
//     group: state.firestore.ordered.merchant[0].group,
//   },
//   loading: state.async.loading,
// });

// const actions = {
//   allocateInventoryItem,
// };

// export default compose(
//   connect(mapStateToProps, actions),
//   reduxForm({
//     form: "registerDeviceWithOutlet",
//     destroyOnUnmount: true,
//     forceUnregisterOnUnmount: true,
//     validate,
//   }),
//   firestoreConnect(query)
// )(RegisterDeviceWithOutlet);
