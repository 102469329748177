import moment from "moment";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-firebase";

import firebase from "../../app/config/firebase";
import { FETCH_TODAYS_TRANSACTIONS } from "./depositsConstants";
import { FETCH_UNALLOCATED_TRANSACTIONS } from "./depositsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
// import { fetchMerchantTransactionsList } from '../../app/data/firebaseAPI';
//import {fetchMerchantTransactionsList} from '../../app/data/firebaseAPI'

export const getTransactionsForDashboard = (values) => async (dispatch) => {
  const firestore = firebase.firestore();

  const transactionsStartDate = values.startDate; // set to 12:00 am today
  const transactionsEndDate = values.endDate;

  const transactionsRef = firestore.collection("receiptIT");

  try {
    dispatch(asyncActionStart());

    let query;

    console.log("transactionsStartDate", transactionsStartDate);
    console.log("transactionsEndDate", transactionsEndDate);

    query = transactionsRef
      .where("txnIdCatName", "in", ["RECEIPTS", "TRANSFERS IN"])
      .where("acceptanceCalendarDateTime", ">=", transactionsStartDate)
      .where("acceptanceCalendarDateTime", "<=", transactionsEndDate)
      .orderBy("acceptanceCalendarDateTime");

    let querySnap = await query.get();

    let transactions = [];

    console.log("querySnap.docs.length", querySnap.docs.length);

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_TODAYS_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id,
      };
      transactions.push(transaction);
    }
    console.log("transactions", transactions);
    dispatch({ type: FETCH_TODAYS_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    // return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const batchWriteDeposits = async (values) => {
  console.log("values", values);
  let finaldepositBatch = [];
  values.map((deposit) => {
    const depositRecord = {
      date: deposit.Date,
      serviceFee: deposit["SERVICE FEE"],
      amount: deposit.Amount,
      description: deposit.DESCRIPTION,
      reference: deposit.REFERENCE,
      compositeKey: deposit.compositeKey,
      chequeNumber: deposit["CHEQUE NUMBER"],
      balance: deposit.Balance,
      dateCreated: new Date(),
      isPosted: false,
    };
    finaldepositBatch.push({
      accId: "00000000000062591940814",
      acceptanceBranchLocation: "NO LOCN",
      acceptanceCalendarDateTime: depositRecord.date,
      acceptanceChannel: "ADT Cash",
      correctionRequired: "No",
      createdBy: "Manual Input",
      dateCreated: new Date(),
      isCredited: false,
      msgId: "",
      reconEffectiveDate: new Date(),
      reconPostDate: new Date(),
      reconReference: depositRecord.reference,
      reconTraceIdentifier: `${depositRecord.date}-${depositRecord.amount}-${depositRecord.reference}`,
      reconTransactionAmount: depositRecord.amount,
      riskAdjustmentIndicator: "N",
      riskOperatingLimitIndicator: "X",
      riskReversalIndicator: "N",
      txnIdCatCode: "RC",
      txnIdCatName: "RECEIPTS",
      txnIdDIndicator: "C",
      txnIdSubCatCode: "ELRC",
      txnIdSubCatName: "ELECTRONIC RECEIPT",
      txnReturnCode: "",
      txnReturnDescription: "AUTH CANCELLED",
      compositeKey: depositRecord.compositeKey,
      updatedBy: "Manual Input",
    });
  });

  console.log("finaldepositBatch", finaldepositBatch);
  try {
    const batch = firebase.firestore().batch();
    finaldepositBatch.map((device) => {
      batch.set(
        firebase.firestore().collection("receiptITMock1").doc(),
        device
      );
    });

    console.log("Batch entry", batch);
    await batch.commit();
    console.log("batch write inventory", finaldepositBatch);
  } catch (error) {
    console.log(error);
    // dispatch(asyncActionError());
  }
};

export const getUnallocatedTransactionsForDashboard =
  (values) => async (dispatch) => {
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("receiptIT");

    try {
      dispatch(asyncActionStart());

      let query;

      query = transactionsRef
        .where("txnIdCatName", "in", ["RECEIPTS", "TRANSFERS IN"])
        .where("isCredited", "==", false)
        .orderBy("acceptanceCalendarDateTime");

      let querySnap = await query.get();

      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({
          type: FETCH_UNALLOCATED_TRANSACTIONS,
          payload: { transactions },
        });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      console.log("transactions", transactions);

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      dispatch({
        type: FETCH_UNALLOCATED_TRANSACTIONS,
        payload: { transactions },
      });
      dispatch(asyncActionFinish());
      // return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const updateAccount = (values) => async (dispatch) => {
  const firestore = firebase.firestore();

  try {
    dispatch(asyncActionStart());

    await firestore.collection("receiptIT").doc(values.msgId).set(
      {
        reconReference: values.account,
        updatedBy: values.currentUser,
        correctionRequired: "Yes",
        dateUpdated: new Date(),
      },
      { merge: true }
    );

    dispatch(
      getTransactionsForDashboard({
        startDate: values.startDate,
        endDate: values.endDate,
      })
    );
    dispatch(asyncActionFinish());
    toastr.success("Success", "Record has been updated.");
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};

export const approveDeposit = (values) => async (dispatch) => {
  const firestore = firebase.firestore();

  try {
    dispatch(asyncActionStart());

    await firestore.collection("receiptIT").doc(values.msgId).set(
      {
        reconReference: values.pendingAuthreconReference,
        pendingAuth: false,
        correctionRequired: "Yes",
        dateUpdated: new Date(),
      },
      { merge: true }
    );

    dispatch(asyncActionFinish());
    toastr.success("Success", "Record has been updated.");
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};

export const rejectDeposit = (values) => async (dispatch) => {
  const firestore = firebase.firestore();

  try {
    dispatch(asyncActionStart());

    await firestore.collection("receiptIT").doc(values.msgId).set(
      {
        pendingAuthreconReference: "",
        pendingAuth: false,
        correctionRequired: "Yes",
        dateUpdated: new Date(),
      },
      { merge: true }
    );

    dispatch(asyncActionFinish());
    toastr.success("Success", "Record has been rejected and sent back.");
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};

export const sendForAuthorization = (values) => async (dispatch) => {
  const firestore = firebase.firestore();
  const storage = firebase.storage();

  try {
    dispatch(asyncActionStart());

    const storageRef = storage.ref();
    const fileRef = storageRef.child(
      `unallocatedDeposits/${values.msgId}/${values.proof[0].name}`
    );
    await fileRef.put(values.proof[0]);
    const url = await fileRef.getDownloadURL();
    await firestore.collection("receiptIT").doc(values.msgId).set(
      {
        pendingAuthreconReference: values.account,
        pendingAuthPopURL: url,
        pendingAuth: true,
        updatedBy: values.currentUser,
        dateUpdated: new Date(),
      },
      { merge: true }
    );

    dispatch(
      getTransactionsForDashboard({
        startDate: values.startDate,
        endDate: values.endDate,
      })
    );
    dispatch(asyncActionFinish());
    toastr.success("Success", "Record has been sent for authorisation.");
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};

export const clearTodaysDeposits = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let transactions = [];
    dispatch({ type: FETCH_TODAYS_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const clearUnallocatedDeposits = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let transactions = [];
    dispatch({
      type: FETCH_UNALLOCATED_TRANSACTIONS,
      payload: { transactions },
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
